<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!-- SECTION PREVIEWER -->
        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-mineralgreen">
            <div class="container mx-auto">
                <div class="p-5 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">The Enterprise</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- ABOUT -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="col-12 flex flex-col items-center">
                        <h1 class="text-center text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Welcome to International Bio Cosmetics</h1>
                        <div class="bg-lightgreen p-1 mt-3 w-72"></div>
                    </div>
                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <p class="text-2xl text-mineralgreen font-black font-epigrafica my-4">Our core business is cosmetic... but natural !</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-medium mb-5">
                                    International Bio Cosmetics (IBC) is a company specialized in the manufacture, production and marketing of cosmetic products essentially based on natural ingredients and plants.<br><br>
                            
                                    Created and established in Ivory Coast since 2019, IBC offers through the expertise of its laboratories, effective and innovative beauty products inspired by our grandmother's recipes.
                                </p>
                                <p class="font-epigrafica text-center text-lightgreen text-3xl font-thin mb-5 mt-10 px-2 md:px-32">
                                    "...We wish to be the catalyst of all the beauties, whatever they are..."<br>
                                    <span class="text-base font-bold">General Manager at IBC</span>
                                </p>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <q-img width="80%" src="@/assets/media/ibc-international-bio-cosmetics-products-family.png" no-spinner />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <!-- VISION -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="col-12 flex flex-col items-center">
                        <h1 class="text-center text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Our Mission</h1>
                        <div class="bg-lightgreen p-1 mt-3 w-28"></div>
                    </div>
                    <div class="container mx-auto py-16">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <q-img class="img-smooth-zoom" width="100%" src="@/assets/media/ibc-international-bio-cosmetics-mission.png" no-spinner />
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 p-5">
                                <p class="text-2xl text-mineralgreen font-black font-epigrafica my-4">To reveal the beauty of women and men through quality products inspired by nature</p>
                                <p class="font-epigrafica text-mineralgreen-dark text-xl text-justify font-medium mb-5">
                                    IBC puts you at the heart of its business and its offerings, and because you are entitled to the best, if not the best, we have made it our mission to reveal your beauty to the world, men and women.<br><br>
                                </p>
                                <p class="font-epigrafica text-center text-lightgreen text-3xl font-thin mb-5 mt-10 md:px-32">
                                    "We are inspired by Nature and motivated to showcase your beauty..."<br>
                                    <span class="text-base font-bold">Deputy Managing Director at IBC</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <!-- VALORS -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="col-12 flex flex-col items-center">
                        <h1 class="text-center text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Our values</h1>
                        <div class="bg-lightgreen p-1 mt-3 w-28"></div>
                    </div>
                    <div class="container mx-auto py-20">
                        <div class="row justify-around">
                            <div class="col-12 col-sm-6 col-md-3 flex flex-col items-center">
                                <q-img fit="cover" width="100px" src="@/assets/media/valors/ibc-international-bio-cosmetics-valors-quality.svg" no-spinner no-transition/>
                                <p class="text-lightgreen text-center text-xl text-bold mt-2 px-20">Quality</p>
                                <p class="text-mineralgreen text-center text-md mt-2 px-20">More than a value, a commitment. Because you deserve the best.</p>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                                <q-img fit="cover" width="100px" src="@/assets/media/valors/ibc-international-bio-cosmetics-valors-passion.svg" no-spinner no-transition/>
                                <p class="text-lightgreen text-center text-xl text-bold mt-2 px-20">Passion</p>
                                <p class="text-mineralgreen text-center text-md mt-2 px-20">We have a heart for our work. It's a guarantee of success. Passionate and motivated for you.</p>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                                <q-img fit="cover" width="100px" src="@/assets/media/valors/ibc-international-bio-cosmetics-valors-innovation.svg" no-spinner no-transition/>
                                <p class="text-lightgreen text-center text-xl text-bold mt-2 px-20">Innovation</p>
                                <p class="text-mineralgreen text-center text-md mt-2 px-20">It is at the heart of our business, we are constantly looking for what makes the difference for you.</p>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                                <q-img fit="cover" width="100px" src="@/assets/media/valors/ibc-international-bio-cosmetics-valors-eco.svg" no-spinner no-transition/>
                                <p class="text-lightgreen text-center text-xl text-bold mt-2 px-20">Eco-citizenship</p>
                                <p class="text-mineralgreen text-center text-md mt-2 px-20">Respectful of nature. Committed to a healthy and preserved environment. This remains our greatest value.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/en/Navbar"
import footerbar from "@/views/shared/en/Footer"
import { useMeta } from "quasar"

export default {
    name: "Enterprise",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Notre Entreprise', // sets document title
        })
    }
};
</script>